@mixin desktop-only {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin mobile-only {
  @media (max-width: 767px) {
    @content;
  }
}

:root {
  font-size: 50%; //1rem = 8px
  @include desktop-only {
    font-size: 62.5%; // 1rem = 10px
  }
}

html,
body {
  font-family: var(--font-family);
  color: var(--body-color);
  letter-spacing: -0.04em;
}
body {
  margin: 0;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  > * {
    width: 100%;
    overflow: hidden;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(-45deg, #ee7752, #e9ff99, #23d2d5, #00fbff);
    background-size: 400% 400%;
    animation: gradient 30s ease infinite;
    opacity: 0.5;
    z-index: -1;
  }
  @media screen and (max-width: 996px) {
    &:before {
      display: none;
    }
    min-height: 100vh;
    padding-bottom: 150px;
    background: linear-gradient(to bottom, #e9ff99, #00fbff);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0 0 0.5rem;
  letter-spacing: -0.04em;
}
.h1,
.h2,
.h3,
.h4,
.h5 {
}

.no-underline {
  text-decoration: none;
}

h1,
.h1 {
  font-size: 4rem;
  margin: 0 0 1rem;
}
h2,
.h2 {
  font-size: 3.5rem;
}
h3,
.h3 {
  font-size: 3rem;
}
h4 {
  font-size: 2rem;
}
h5 {
  font-size: 1.5rem;
}
p {
  font-size: 2rem;
  margin: 0 0 0.5rem;
}
a {
  font-size: 1.5rem;
  color: var(--body-color);
  transition: color 0.3s ease;
  margin: 0;
  text-underline-offset: 0.1em;
  &:hover {
    color: var(--primary);
  }
}

html {
  overflow-y: scroll;
}

.content {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  max-width: 100%;
  width: min(90%, 1200px);
  margin-inline: auto;

  > div:first-child {
    position: relative;
    max-width: 100%;
    padding: 2rem 1rem;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      display: block;
      background: linear-gradient(
          to bottom right,
          rgba(white, 0.9) 0%,
          rgba(white, 0) 100%
        )
        no-repeat center/contain;
      max-height: 0;
      max-width: 0;
      transition: all 0.3s ease;
    }
    border-radius: 10px;
    overflow: hidden;
  }

  .done &,
  .ready & {
    > div {
      &:before {
        max-height: 100%;
        max-width: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}
