.nav {
    position: relative;
    text-align: center;
    width: 100%;
    top: 0;
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      width: 100%;
      backdrop-filter: blur(40px);
      padding: 1rem;
      background: linear-gradient(
        to bottom,
        rgba(white, 0.3) 0%,
        rgba(white, 0.1) 100%
      );
      box-shadow: 0px 0px 10px rgba(black, 0.2);
      h1 {
        margin: 0;
        font-weight: 700;
        @media screen and (max-width: 768px) {
          font-size: 3rem;
        }
      }
      a {
        width: 20%;
      }
    }
    &__items {
      list-style: none;
      padding: 1rem 2rem;
      display: flex;
      justify-content: center;
      max-width: 100%;
      li {
        max-width: calc(25% - 2rem);
      }
      @media screen and (max-width: 768px) {
        padding: 1rem 0 2rem;
        width: 100%;
        margin: 0 auto;
        flex-flow: row wrap;
      }
    }
    &__item {
      display: block;
      position: relative;
      text-decoration: none;
      max-width: 100%;
      width: 148px;
      height: 25px;
      margin: 0 1rem;
      
      @media screen and (max-width: 768px) {
        margin: 1rem .5rem;
      }
  
      &::after{
        content: "";
        display: block;
        width: 0;
        height: 2px;
        background-color: var(--primary-light);
        position: absolute;
        bottom: 0;
        left: 50%;
        transition: all .3s ease;
      }
  
      &:hover, &.active {
        &::after {
          width: 100%;
          left: 0;
        }
      }
    }
  }