.bgWrap {
  position: absolute;
  pointer-events: none;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .x1 {
    animation: animateCloud 25s linear infinite;
    transform: scale(0.25) translateY(100%);
  }
  .x2 {
    animation: animateCloud 30s linear infinite;
    transform: scale(0.3);
  }
  .x3 {
    animation: animateCloud 70s linear infinite;
    transform: scale(0.5) translateY(-100%);
  }
  .x4 {
    animation: animateCloud 125s linear infinite;
    transform: scale(0.4);
  }
  .x5 {
    animation: animateCloud 100s linear infinite;
    transform: scale(0.55);
  }
  .cloud {
    background: #fff;
    background: linear-gradient(to top, #fff 5%, #f1f1f1 100%);
    border-radius: 100px;
    box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    z-index: -1;
    height: 120px;
    position: relative;
    width: 350px;
    &:before,
    &:after {
      background: #fff;
      content: "";
      position: absolute;
      z-index: -1;
    }
    &:after {
      border-radius: 100px;
      height: 100px;
      left: 50px;
      top: -50px;
      width: 100px;
    }
    &:before {
      border-radius: 200px;
      width: 180px;
      height: 180px;
      right: 50px;
      top: -90px;
    }
  }
}

.sun {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: max(40vw, 300px);
  height: max(40vw, 300px);
  transform: translate(-50%, -50%);

  .layer {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: rgb(255, 221, 85);
    display: block;
    transform-origin: top left;
    &:nth-child(1) {
      opacity: 1;
      width: 60%;
      height: 60%;
    }
    &:nth-child(2) {
      opacity: 0.6;
      width: 80%;
      height: 80%;
    }
    &:nth-child(3) {
      opacity: 0.3;
      width: 100%;
    }
  }
}


@keyframes animateCloud {
    0% {
      margin-left: -740px;
    }
    100% {
      margin-left: 100vw;
    }
  }